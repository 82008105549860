import React, { useState } from 'react'
import { Box, Text, Image } from 'rebass'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'
import '../styles/index.sass'

import tz1 from './DSC_7790.jpg'
import tz2 from './DSC_6748.jpg'
import tz3 from './pez-3.jpg'
import tz4 from './4-rompe-olas.jpg'
import tz5 from './galindez-cerebro-keigh-haring.jpg'
import tz6 from './limon.jpg'
import tz7 from './coralito1.jpg'
import tz8 from './limon2.jpg'
import tz9 from './cerebro-complejo.jpg'
import tz10 from './DSC_2130.jpg'
import tz11 from './DSC_9464.jpg'
import tz12 from './DSC_7780.jpg'

const coralesData = [
    { foto: tz1, latin: 'Orbicella faveolata' },
    { foto: tz2, latin: 'Orbicella annularis' },
    { foto: tz3, latin: 'Dendrogyra cylindrus' },
    { foto: tz4, latin: 'Pseudodiploria strigosa' },
    { foto: tz6, latin: 'Colpophyllia natans' },
    { foto: tz5, latin: 'Pseudodiploria strigosa' },
    { foto: tz6, latin: 'Colpophyllia natans' },
    { foto: tz12, latin: 'Pseudodiploria clivosa' },
    { foto: tz8, latin: 'Colpophyllia natans' },
    { foto: tz9, latin: 'Pseudodiploria strigosa' },
    { foto: tz10, latin: 'Pseudodiploria clivosa' },
    { foto: tz11, latin: 'Montastrea cavernosa' },
    { foto: tz7, latin: 'Colpophyllia natans (end lazum coral ID)' },
    { foto: 'https://reefguide.org/pix/pillarcoral4.jpg', latin: 'Dendrogyra cylindrus' },
    { foto: 'https://reefguide.org/pix/ellipticalstarcoral1.jpg', latin: 'Dichocoenia stokesi' },
    { foto: 'https://reefguide.org/pix/mazecoral3.jpg', latin: 'Meandrina meandrites' },
    { foto: 'https://reefguide.org/pix/whitevalleymazecoral1.jpg', latin: 'Meandrina jacksoni' },
    { foto: 'https://reefguide.org/pix/groovedbrain3.jpg', latin: 'Diploria labyrinthiformis' },
    { foto: 'https://reefguide.org/pix/symmetricalbrain5.jpg', latin: 'Pseudodiploria strigosa' },
    { foto: 'https://reefguide.org/pix/knobbybraincoral1.jpg', latin: 'Pseudodiploria clivosa' },
    { foto: 'https://reefguide.org/pix/boulderbraincoral7.jpg', latin: 'Colpophyllia natans' },
    { foto: 'https://reefguide.org/pix/lobedstarcoral1.jpg', latin: 'Orbicella annularis' },
    { foto: 'https://reefguide.org/pix/mountainousstarcoral1.jpg', latin: 'Orbicella faveolata' },
    { foto: 'https://reefguide.org/pix/boulderstarcoral1.jpg', latin: 'Orbicella franksi' },
    { foto: 'https://reefguide.org/pix/greatstarcoral2.jpg', latin: 'Montastrea cavernosa' },
    { foto: 'https://reefguide.org/pix/massivestarlet2.jpg', latin: 'Siderastrea siderea' },
    { foto: 'https://reefguide.org/pix/knobbycactus2.jpg', latin: 'Mycetophyllia aliciae' },
    { foto: 'https://www.meerwasser-lexikon.de/imgHaupt/26456_gLScTYxTDR.jpg', latin: 'Mycetophyllia daniana' },
    { foto: 'https://reefguide.org/pix/roughcactuscoral1.jpg', latin: 'Mycetophyllia ferox' },
]
const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        }
    },
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity
}

export const Example = () => {
    const [[page, direction], setPage] = useState([0, 0])
    const [showText, setShowText] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const imageIndex = wrap(0, coralesData.length, page)

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection])
    }

    const handleCardMouseUp = (e, i) => {
        if (!isDragging) {
            setShowText(!showText)
        }
    }
    //const bgColor = useColorModeValue('black', 'gray.900')
    return (
        <>
            <AnimatePresence initial={false} custom={direction}>
                {showText ? (
                    <motion.div
                        onMouseUp={handleCardMouseUp}
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial='enter'
                        animate='center'
                        exit='exit'
                        transition={{
                            x: { type: 'spring', stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                        }}
                        drag='x'
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragStart={(e, { offset, velocity }) => {
                            setIsDragging(true)
                        }}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x)

                            if (swipe < -swipeConfidenceThreshold) {
                                paginate(1)
                            } else if (swipe > swipeConfidenceThreshold) {
                                paginate(-1)
                            }
                            setIsDragging(false)
                        }}
                    >
                        <Box>
                            <Box h={'210px'} bg={'gray.100'} pos={'relative'}>
                                <Image pointerEvents='none' height={230} width={282} objectFit={'cover'} src={coralesData[imageIndex].foto} />
                            </Box>
                            <Text fontFamily='Nunito, Helvetica, Arial, sans-serif'>{coralesData[imageIndex].latin}</Text>
                        </Box>
                    </motion.div>
                ) : (
                    <motion.img
                        objectFit={'cover'}
                        onMouseUp={handleCardMouseUp}
                        key={page}
                        src={coralesData[imageIndex].foto}
                        custom={direction}
                        variants={variants}
                        initial='enter'
                        animate='center'
                        exit='exit'
                        transition={{
                            x: { type: 'spring', stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                        }}
                        drag='x'
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragStart={(e, { offset, velocity }) => {
                            setIsDragging(true)
                        }}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x)

                            if (swipe < -swipeConfidenceThreshold) {
                                paginate(1)
                            } else if (swipe > swipeConfidenceThreshold) {
                                paginate(-1)
                            }
                            setIsDragging(false)
                        }}
                    />
                )}
            </AnimatePresence>
            <div className='next' onClick={(e) => paginate(1)}>
                {'‣'}
            </div>
            <div className='prev' onClick={(e) => paginate(-1)}>
                {'‣'}
            </div>
        </>
    )
}

const Corales = () => (
    <Box backgroundColor='black' m={0} p={0} style={{ height: '100vh', width: '100vw' }} className='coralesbody'>
        <Example />
    </Box>
)

export default Corales
